@tailwind base;
@layer base {
    a[class~="link"] {
        @apply text-slate-900 no-underline font-semibold border-b border-sky-300 hover:border-b-2 dark:text-white dark:border-sky-400;
    }

    hr {
        @apply border-primary;
    }
    h1 {
        @apply text-3xl font-extrabold tracking-wide;
    }
    h2 {
        @apply text-2xl font-bold tracking-wide;
    }
    h3 {
        @apply text-xl text-slate-900 font-semibold mb-[.6em] tracking-wide;
    }

    h2,
    h3,
    h4,
    thead th {
        @apply dark:text-slate-200;
    }

    pre {
        overflow-x: auto !important;
        white-space: pre-wrap !important;
        white-space: -moz-pre-wrap !important;
        white-space: -pre-wrap !important;
        white-space: -o-pre-wrap !important;
        word-wrap: break-all !important;
    }
}

@tailwind components;

@layer components {
    .btn {
        @apply font-semibold rounded py-2 px-4;
    }
    .btn-primary {
        @apply bg-sky-500 text-white hover:bg-sky-600;
    }
    .btn-danger {
        @apply bg-red-500 text-white hover:bg-red-600;
    }
    .btn-success {
        @apply bg-green-500 text-white hover:bg-green-600;
    }

    .text-primary {
        @apply text-sky-500 dark:text-sky-400;
    }
    .text-danger {
        @apply text-red-500 dark:text-red-400;
    }
    .text-success {
        @apply text-green-500 dark:text-green-400;
    }
    .max-w-8xl {
        max-width: 90rem;
    }
    .border-primary{
        @apply border-slate-900/10 dark:border-slate-300/10
    }
}

@tailwind utilities;
@layer utilities {
    /* width */
    ::-webkit-scrollbar {
        @apply w-2 h-2 bg-transparent;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        @apply bg-slate-100 rounded dark:bg-slate-500/[0.16];
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        @apply rounded bg-slate-300 hover:bg-slate-400/70  dark:bg-slate-500/50  dark:hover:bg-slate-400/70;
    }

    .SideBar ::-webkit-scrollbar,
    .scroll-xs ::-webkit-scrollbar {
        @apply w-1 h-1;
    }
}
